/* SEARCH */

.searchform {
    position: fixed;
  
    .searchform__input {
      height: $navbar-btn-size;
      line-height: $navbar-btn-size;
      padding-right: 6rem;
  
      @include bp('expanded') {
        width: 40rem;
      }
    }
  
    .searchform__submit {
      background-color: $color-primary;
      color: white; 
  
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
      border: 0;
      height: $navbar-btn-size - 0.2rem;
      font-weight: normal;
      text-transform: uppercase;
  
      &:hover {
        background-color: $gray-lighter;
        color: $color-primary;
      }
  
      &[disabled] {
        // don't submit less than 3 chars
        background-color: transparent;
        color: $gray-light;
      }
    }
  }
  