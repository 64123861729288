/* CONFIG */

@import "variables";

/* VENDOR LIBS */

@import "bootstrap";

/* LIBS */

@import "lib/cdn-imports";

@import "lib/shadows";
@import "lib/base";
@import "lib/type";
@import "lib/colors";
@import "lib/animation";
@import "lib/buttons";
@import "lib/wordstrap";
@import "lib/breakpoints";
@import "lib/fixed-ratio";
@import "lib/flexgrid";
@import "lib/layout";
@import "lib/sticky-header";
@import "lib/nav";
@import "lib/navbar";
@import "lib/ripple";
@import "lib/styleguide";
@import "lib/style-ie";

@import "lib/debug";

@import "lib/foo";

/* APP */
@import "sections/home";
@import "sections/navbar";
@import "sections/footer";
@import "sections/parcours";
@import "sections/achats";
@import "sections/services";
@import "sections/category";
@import "sections/catalogue";
@import "sections/contact";
@import "sections/blog";
@import "sections/tile-post";
@import "sections/search";
@import "app";

// @import "demo";