
$foo: $brand-primary;


/*
@styleguide

# FOO

*/


/*
@styleguide

## foof (`$some-number`, `$another-number`)

This function allows you to fooify things

* `$some-number` : sort of value
* `$another-number` : yet another one
* RETURNS : sum of values


    $foo: foof(4, 2);  // 6

*/

@function foof($some-number, $another-number) {
  @return $some-number + $another-number
};

/*
@styleguide

## Basics

Use the button classes on an `<a>`, `<button>`, `<input>` element.

<button class="foo">Foo Button</button>

    <button class="foo">Foo Button</button>

*/

.foo {
  padding: 1em;
  font-weight: bold;
  background-color: transparent;
  text-transform: uppercase;

  color: $foo;
  border: 4px solid $foo;

  &:hover {
    background-color: $foo;
    color: white;
  }
}

/*
@styleguide

## Simple foo

Here is the `simple` variant!

<button class="foo foo--simple">Simple Button</button>

    <button class="foo foo--simple">Simple Button</button>

*/

.foo--simple {
  color: gray;
  border: 4px solid gray;

  &:hover {
    background-color: gray;
    color: white;
  }
}

/*
@styleguide

## Small foo

Here is the `small` variant!

<button class="foo foo--small">Small Button</button>

    <button class="foo foo--small">Small Button</button>

## Small simple foo

<button class="foo foo--simple foo--small">Small Button</button>

    <button class="foo foo--simple foo--small">Small Button</button>

*/

.foo--small {
  padding: 0.5em;
  font-size: 0.8em;
}

/*
@styleguide

# Styleguide rules

*/

pre {
  background-color: rgba(0,0,0,0.05);
  padding: 1em;
  margin-left: -1em;
  margin-right: -1em;
}
