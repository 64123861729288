/* FLEXGRID */

.flexrow {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 0 calc(100% - 30px);
  }
}

// $n-col-max: 12;
$prefix-flex: 'flex-';

@each $breakpoint-name, $breakpoint-width in $breakpoints {

  @include bp($breakpoint-name) {

    // OMG...
    .#{$prefix-flex}#{$breakpoint-name}-1 { flex-basis: calc(8.33% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-2 { flex-basis: calc(16.66% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-3 { flex-basis: calc(25% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-4 { flex-basis: calc(33.33% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-5 { flex-basis: calc(41.66% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-6 { flex-basis: calc(50% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-7 { flex-basis: calc(58.33% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-8 { flex-basis: calc(66.66% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-9 { flex-basis: calc(75% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-10 { flex-basis: calc(83.33% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-11 { flex-basis: calc(91.66% - 30px); }
    .#{$prefix-flex}#{$breakpoint-name}-12 { flex-basis: calc(100% - 30px); }

    // @for $i from 1 through $n-col-max {
    //   .#{$prefix-flex}#{$breakpoint-name}-#{$i} {
    //     flex-basis: calc((100% * $n-col-max / $i) - 30px);
    //   }
    // }
  }
}


.flexrow--gutter {
  margin-left: -$gutter-half;
  margin-right: -$gutter-half;

  > * {
    margin: $gutter-half;
  }
}
