/*
@styleguide

# App

*/
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700|PT+Serif');

h1, h2, h3 {
  color: $color-primary;
  margin-bottom: $gutter;
}
div {
  line-height: 1.8;
}

.titleh2--featured {
  font-size: 45px;
  text-transform: uppercase;
  letter-spacing: 1rem; 
  margin-top: 0;
  @media (min-width: $screen-sm) {
    margin-top: 24px;
  }
}
.history__title .titleh2--featured{
  color: $color-secondary;
}
.section {
  padding: $gutter 0;
  @media (min-width: $screen-md) {
    padding: $gutter*2 0;
  }
}

.uppercase {
  text-transform: uppercase;
}
.flex-padding {
  padding: 1rem;

  @media (min-width: $screen-md) {
    padding: $gutter;
  }
}

.flex-hidden-sm {
  display: none;
  @media (min-width: $screen-md) {
    display: block;
  }
}
.flex-hidden-xs {
  display: none;
  @media (min-width: $screen-sm) {
    display: block;
  }
}
.flex-hidden-min {
  display: none;
  @media (min-width: $screen-xs) {
    display: block;
  }
}

.flex-visible-xs {
  display: block;
  @media (min-width: $screen-sm) {
    display: none;
  }
}
.site-content .btn,
.woocommerce #respond input#submit.alt, 
.woocommerce a.button.alt, 
.woocommerce button.button.alt, 
.woocommerce input.button.alt,
.woocommerce .cart .button, 
.woocommerce .cart input.button,
.gform_button {
  background-color: transparent !important; 
  text-transform: uppercase;
  color: $color-primary !important;
  border: 1px solid $color-primary;
  border-radius: 50px;
  padding: 1rem $gutter;
  margin: $gutter 0;
  display: inline-table;
  line-height: 2;
  white-space: normal;  
}

.btn:hover,
.searchform input[type="submit"]:hover,
.woocommerce #respond input#submit.alt:hover, 
.woocommerce a.button.alt:hover, 
.woocommerce button.button.alt:hover, 
.woocommerce input.button.alt:hover,
.woocommerce .cart .button:hover, 
.woocommerce .cart input.button:hover,
.btn-default.disabled:hover, 
.searchform input.disabled[type="submit"]:hover, 
.btn-default.disabled:focus, .searchform input.disabled[type="submit"]:focus, 
.btn-default.disabled.focus, .searchform input.disabled.focus[type="submit"], 
.btn-default[disabled]:hover, .searchform input[disabled][type="submit"]:hover, 
.btn-default[disabled]:focus, .searchform input[disabled][type="submit"]:focus, 
.btn-default[disabled].focus, .searchform input[disabled].focus[type="submit"],
.gform_button:hover {
  color: white !important; 
  background-color: $color-primary !important;
}
#add_payment_method .wc-proceed-to-checkout a.checkout-button, 
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button, 
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
  padding: 1rem 3rem !important;
  font-size: 16px !important;  
}
.site-wrapper a {
  color: black;
  &:hover {
    color: $color-primary;
    text-decoration: none;
  }
  &.btn:hover {
    color: white;
    text-decoration: none;
  }
}
.wrapper--footer a{
  color: white;
}
.breadcrumb_last {
  color: $color-primary;
}
.flexrow {

  justify-content: center;
  
  @media (min-width: $screen-sm) {
    justify-content: initial;
  }
}
//Pagination 
.tool-pagination {
  display: flex;
  justify-content: flex-end;
 
  .pages {
    display: flex;
  }
  li {
    padding: 0 10px;
    margin-right: 5px;
    border: 1px solid #999;
    &:hover {
      border: 1px solid black;
    }
  }
  .current {
    background-color: $color-primary;
    color: white;
  }
}
