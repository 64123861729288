
/*Page Categories*/
.catalogue-categories ul{
  padding-left: 0;
}
.catalogue-category {
  text-align: center;
  cursor: pointer;
  max-width: 350px;
  list-style: none;
  
  .catalogue-category__image {
    height: 350px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; 

    &:hover {
      border: 1px solid $color-secondary;
    }

  }

  .catalogue-category__name {
    padding: 1rem;

    a {
      color: black;
      text-transform: uppercase; 
      font-size: $font-size-base;
      font-weight: bold;

      &:hover {
        color: $color-primary;
        text-decoration: none;
      }
    }
  }
}
/*Page Categorie*/
.wrapper--category-filters {
  padding-top: 1rem;
  @media (min-width: $screen-sm) {
    padding-top: $gutter-quad;
  }
  .filters {
    display: none;
    @media (min-width: $screen-sm) {
      display: block;
    }
  }
  .filters-expanded {
    display: block;
  }
  .btn-toggle {
    outline: 0.1rem solid rgba(0, 0, 0, 0.25);
  }
}
.wrapper--products {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    width: 100%;
    @include bp('sm') {
      width: 75%;
    }
    
  }
}
.filters-label {
  text-transform: uppercase;
}
.products {
  .product {
    cursor: pointer;
  }
  .flexrow > * {
    flex-grow: 0;
  }
  .media-content {
    text-align: center;
  }
  .woocommerce-loop-product__title {
    padding: .5em 0;
    margin: 0;
    font-size: 1em;
  }
  .woocommerce-Price-amount {
    color: $color-primary;
  }
}

/*Single product*/
ol.flex-control-thumbs {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-summary {
  display: flex;
  flex-direction: column;

  .product__details {
    flex-grow: 1;
  }
}
.caracteristique {
  margin: 1rem 0;

  .caracteristique-label {
    font-weight: normal;
    color: #a7a7a7;
  } 
  &.caracteristique__prix {
    .caracteristique-value {
      font-size: 28px;
    }
  }

  .value__item{
    &::after {
      content: ' / ';
    }
    &:last-child::after {
      content: ' ';
    }
   
  }
}
.caracteristique__dimension {
  .caracteristique-value {
    &::after {
      content: ' * ';
    }
    &:last-child::after {
      content: ' '; 
    }
  }
}
.outofstock {
  color: $color-primary;
  text-transform: uppercase;
  padding-left: 1rem;
}
.price--through {
  text-decoration: line-through;
}
.product__action {
  margin: $gutter 0;
  p {
    font-size: 22px;
    font-weight: bold;
  }
  a.btn {
    margin: 0;
  }
}
.woocommerce ul.products  {
  li.product {
    text-align: center;
    .woocommerce-loop-product__title {
      color: black;
    }
    .woocommerce-Price-amount {
      color: $color-primary;
    }
    a img {
      margin: 0 !important;
    }
  }
  .product-images {
    
    
    .product-thumbs {
      display: block;
      overflow: auto;
      margin: 10px 0;
      width :100%;
    }
    
    .product-thumbs .thumb {
      float: left;
      margin-bottom: 10px;
      width: 30%;
    }
    
    .product-thumbs .thumb:nth-child(2) {
      margin: 0 5%;
    }
  }
  
}
.post-type-product .flex-control-thumbs li {
  padding: 5px 5px 0 0;
}
/*Produits similaires*/
.wrapper--related-products {
  margin-top: $gutter;
}
.related {
  h2 {
    color: black;
  }
  .product-thumbs {
    display: none;
  }
}


