.blog {
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .tease-post {
    flex-basis: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
   
    @media (min-width: $screen-sm) {
      flex-basis: 48%;
      margin-left: 1%;   
      margin-right: 1%;
      text-align: left;
    }
    @media (min-width: $screen-md) {
      flex-basis: 31%;
      margin-left: 1%;
      margin-right: 1%;
    }
    p {
      flex-grow: 1;
    }
  }
  
}
.metas .terms a {
  margin: 0;
}
.article-img {
  padding: 1rem;
  text-align: center;

  @media (min-width: $screen-md) {
    img {
      width: 100%;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    width: 100%;
    
  }
  
}