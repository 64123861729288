/* STICKY HEADER */

/*body*/.sticky-header {

  .site-header {
    position: fixed;
    top: 0;
    width: 100%;
    @include shadow(1);
    z-index: 20000;  // TODO: better!
  }

  // ADMIN BAR

  &.admin-bar {
    $wp-adminbar-height: 46px;
    $wp-adminbar-height-desktop: 32px;
    $wp-adminbar--bp: 782px;

    // padding-top: $wp-adminbar-height;

    .site-header {
      top: $wp-adminbar-height;

      @media screen and (min-width: $wp-adminbar--bp) {
        top: $wp-adminbar-height-desktop;
      }
    }

    #wpadminbar { position: fixed !important; }
  }

  .site-content{
    padding-top: $navbar-height-mobile; // collapse btns increase height

    @include bp('expanded') {
      padding-top: $navbar-height-desktop + $topbar-height-desktop;
    }
    @include bp('md') {
      padding-top: $navbar-height-desktop + $topbar-height-desktop + 1rem;
    }
    @include bp('lg') {
      padding-top: 9.6rem + $topbar-height-desktop;
    }
  }

}
