.services-img{
  background-image: url('../img/img-page-services.jpg'); 
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  @include bp('md') { 
    background-image: url('../img/img-services.jpg');
    height: 500px;
  }
}  
.services-content__description h2{
  font-size: 22px;
  margin-bottom: 1rem;
}