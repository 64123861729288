
.wrapper--content {
  .gform_wrapper {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .gform_fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .gfield {
        flex-basis: 100%;
        &.gf-half {
          @media (min-width: $screen-sm) {
            flex-basis: 50%;
          } 
        }
      }
      
    }
    .gform_footer {
      text-align: center;
    }
  }
  #label_1_8_1 {
    font-weight: initial;
  }
}
.page-contact {
  h1 {
    text-align: center;
  }
}