.wrapper--parcours {
  margin-bottom: $gutter;
  .parcours-content {
    color: $color-primary;
  
    .parcours-content__description {
      padding-top: $gutter;
    }
  }
  
  .parcours-visu__img {
    background-image: url('../img/img-bijoux.jpg'); 
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    @include bp('md') {
      height: 500px;
    }
  
  }
  .parcours-visu {
    color: $color-primary;
    display: flex;
    flex-direction: column;
  
    .parcours-visu__slogan {
      font-size: 30px;
      font-style: italic;
      text-align: center;
    }
  }
}

.wrapper--bibliotheque {
  background-color: black;
  color: white;

  .bibliotheque-img {
    background-image: url('../img/img-bibliotheque.png'); 
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
  }
}