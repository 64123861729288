
.tile-post {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  @include shadow(1);
  cursor: pointer;

  &:hover {
    @include shadow(2);
  }
  cursor: pointer;
  .tile__body {
    flex-grow: 1;
    padding: 2rem;
  }
  .tile__head {
    height: 0;
    padding-bottom: 80%;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      width: 100%;
    }
  }
  .tile__foot {
    padding: 2rem;
    padding-top: 0;
  }
 
}