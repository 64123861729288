//Slider
.hero--home {
  height: auto!important;  

  div#n2-ss-4 .n2-ss-layer {
    .n2-font-8d141e5d76fc7564d01827bcca827bd9-hover {     
      padding: 0 30px;
      margin-top: 30px;
      line-height: 1;
      @media (min-width: $screen-md) {
        padding: 0 100px;
      }
      @media (min-width: $screen-lg) {
        margin-top: 100px;
      }
    }


    .n2-font-98bdbb8b2d0edc9ca34f52df20df3d87-hover {
      margin-top: 10px;
      margin-bottom: 30px;
      padding: 0 30px ;
      @media (min-width: $screen-md) {
        padding: 0 100px;
      }
    }
  }
  div#n2-ss-4 .n2-ss-layer-content {
   height: auto;
  }
  div#n2-ss-4 .n2-ss-layer[data-type="content"] {
    background-color: rgba(0,0,0,0.2);
      @media (min-width: $screen-sm) {
        background-color: transparent;
      }
  }
  
}

div#n2-ss-4 .n2-ss-layer-content {
  min-height: 50% !important;
}
.slider__title {
  margin: 0!important;
}

.section.section--history {
  background-color: black;
  color: white;
  padding-top: 0;
}

.wrapper--myhistory {
 
  .history
  {
    .history__title {
      position: relative;
      top: 45px;
      display: flex;
      justify-content: flex-end;
      padding-right: 0;
      @media (min-width: $screen-sm) {
        padding-right: $gutter;
      }    
    }    
  }
  .history__img {
    background-image: url('../img/img-histoire.jpg'); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 300px;
 
    @media (min-width: $screen-md) {
      height: 580px;
      background-position: top right;
    }
  }

  .my-history {
    padding: 0 $gutter-half;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: $screen-sm) {
      padding: $gutter;
    }
    @media (min-width: $screen-md) {
      padding: $gutter-triple $gutter-double 0 $gutter-double;
    }
    .my-history__title {    
      color: white;      
    }
    .my-history__content {
      @media (min-width: $screen-lg) {
        max-width: 475px;
      }
      
    }
    
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
      // CSS pour IE versions 10, 11 et Edge
      width: 100%;
      @include bp('md') {
        width: 50%;
      }
    }
    
  } 
}

.art-history { 
  @media (min-width: $screen-md) {
    padding: $gutter;
  }
}

.section--category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .category__content {
    padding: $gutter-half;   
    @media (min-width: $screen-lg) {
      padding: $gutter $gutter-quad;
    }
    
  }
  .category__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

  }
  
  .category__catalogue {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    h2 {
      width: 260px;
    }
    .category__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $gutter-half;   
      @media (min-width: $screen-lg) {
        padding: 0 $gutter-quad;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
        // CSS pour IE versions 10, 11 et Edge
        width: 100%;
        @include bp('sm') {
          width: 50%;
        }
        @include bp('md') {
          width: 41%;
        }
      }
    }
    .category__btn {
      text-align: center;
    }
    .category__img {
      background-image: url('../img/img-catalogue.jpg'); 
      height: auto;
      @media (min-width: $screen-lg) {
        height: 600px;
      }
    }
    
  }
  .category__achat,
  .category__services {
    .category__content {
      padding: $gutter-double $gutter-half;
      text-align: center;
    }
  } 
  .category__achat {
    
    .category__img {
      background-image: url('../img/img-achat.jpg'); 
    }
  } 
  .category__services {
    
    .category__img {
      background-image: url('../img/img-services.jpg'); 
    }
  } 
}

.posts--latest {
  .tile-post {
    max-width: 400px;
    @media (min-width: $screen-sm) {
      max-width: 100%;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
      // CSS pour IE versions 10, 11 et Edge
      
        width: 100%;
        @include bp('md') {
          width: 30%;
        }
     
    } 
  }
}
