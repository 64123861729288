.navbar {
  @include bp('sm') {
    max-height: 6rem;
    
  }
  @include bp('md') {
    max-height: initial;
    
  }

  .navbar-brand { 
    @include bp('sm') {
      position: absolute;
      top: 0.8rem;
    }
    @include bp('md') {
      position: static;
      
    }

    img {
      max-width: 140px;
      
      @media (min-width: $screen-sm) {
        max-width: 165px;
      }
      @media (min-width: $screen-lg) {
        max-width: 250px;
      }
    }
  }
  .bar-filler {
    flex: 1;   
  }
  .menu-item a {
    color: black;
    &:hover {
      color: $color-primary;
    }
  }

  .menu-primary .menu-item.current-menu-item a, 
  .menu-secondary .menu-item.current-menu-item a {
    color: $color-primary;
    background-color: white;
  }
  .menu-primary .menu-item > a, 
  .menu-secondary .menu-item > a {
    padding-left: 1.2rem;
    padding-right: 1.2rem;

  }
 

  @media (min-width: $screen-sm) {
    .navbar-header {
      flex-basis: 100%;
    }
    .navbar-brand {
      height: auto;
    }
    .navbar-menu {
      flex: 1; 
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
        // CSS pour IE versions 10, 11 et Edge
        flex: auto; 
      } 
    }
    .bar-filler {
      flex: 0; 
    }
    .menu-item,
    .navbar-menu {
      display: flex;
      flex-direction: column;
      justify-content: center; 
    } 
    
    .menu-primary {        
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; 
    }
  }
  @media (min-width: $screen-md) {
    .navbar-header {
      flex-basis: auto;
    }
  }
   
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {    
  @include bp('md') {
    .site-header {
      height: 156px;
    }
  }
}

.topbar-menu {
  margin-left: $gutter;
}
.fa-user, .fa-shopping-basket {
  color: #777777; 
}
.btn-cart {
  display: inline-block;
}
.bar-wrapper .searchform input[type="submit"] {
  color: white;
  background-color: $color-primary; 
}