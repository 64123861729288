.block-category { 
  text-align: center; 
  display: flex;
  flex-direction: column;
  padding: $gutter;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    width: 100%;
    @include bp('sm') {
      width: 50%;
    }
    
  }

  .block-category__description {
    flex-grow: 1;
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
  }
}

.block-category:not(.block-category--achats):not(.block-category--services) {
  background-color: $color-ternary;

 
  @media (min-width: $screen-md) {
    padding: $gutter-triple;
  }

  .block-category__title {
    color: black;
  }

}
.block-category:not(.block-category--achats):not(.block-category--services):hover {
  background-color: black;  
  color: white;
  cursor: pointer;

  .block-category__title {
    color: white;
  }
  .btn,
  .btn:hover {
    background-color: transparent;
    color: white;
    border: 1px solid white;
  }
}

.block-category.block-category--achats,
.block-category.block-category--services {
  color: $color-primary;
  padding: 2rem;
  @media (min-width: $screen-sm) {
    padding: $gutter;
  }
  @media (min-width: $screen-lg) {
    padding: $gutter-triple $gutter-double;
  }
  h2 {
    color: $color-primary;
  }

}

.block-category.block-category--achats{
  background-color: $color-secondary;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    width: 100%;
    @include bp('sm') {
      width: 50%;
    }
    
  }
}

.block-category.block-category--services{
  background-color: $color-ternary;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {      
    // CSS pour IE versions 10, 11 et Edge
    width: 100%;
    @include bp('sm') {
      width: 50%;
    }
    
  }
} 