.site-footer {
  color: white;
  background-color: black;
  padding: $gutter;
  text-align: center;
  a {
    color: white;
  }

  .wrapper--footer-logo {
    padding-bottom: $gutter;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .footer__webcd,  
  .footer__copyright,
  .footer__links {
    padding: 8px;
  }

  .contact-info {
    margin: $gutter 0;
    display: flex;
    justify-content: center;
    svg {
      margin-right: $gutter/2;
    }
    div {
      line-height: 24px;
    }
   
  }
  .social-network {
    display: inline-block;
    margin-right: $gutter/2;
    padding: $gutter/2;
    color: white; 
    &:hover {
      background-color: $color-primary;
      color: white;
    }
  }
}

@media (min-width: $screen-sm) {
  .footer__webcd,  
  .footer__copyright,
  .footer__links {
    padding: 0;
  }
  .footer__webcd {
    text-align: right;
  }
  .footer__copyright {
    text-align: left;  
  }
}